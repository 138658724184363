/* overwrite styles specific to user who do not have JS enabled */
html {
	 h1,
	 h2,
	 h3,
	 h4,
	 h5,
	 h6,
	 p,
	 .navbar,
	 ul,
	 ol,
	 label {
	    opacity: 0 !important;
	}
}

